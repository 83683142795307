import { render, staticRenderFns } from "./CornerButton.vue?vue&type=template&id=67534ed9"
import script from "./CornerButton.vue?vue&type=script&lang=js"
export * from "./CornerButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SpinIcon: require('/vercel/path0/components/icons/SpinIcon.vue').default,CornerIcon: require('/vercel/path0/components/icons/CornerIcon.vue').default})
