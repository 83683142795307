
export default {
  middleware: 'auth',
  auth: 'guest',

  data() {
    return {
      isLoading: false,
      username: '',
      password: '',
    }
  },

  head() {
    return {
      title: 'Login',
    }
  },

  computed: {
    showNewShopBanner() {
      const today = new Date()
      const targetDate = new Date('2023-11-30')
      return today < targetDate
    },
  },

  methods: {
    login() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      this.$auth
        .loginWith('local', {
          data: {
            username: this.username,
            password: this.password,
            client_secret: this.$config.passportClientSecret,
          },
        })
        .then(() => {
          this.$store.dispatch('user/loginSuccessfull')
        })
        .catch(() => {
          this.$refs.form.setErrors({
            username: ['Die Anmeldedaten waren falsch.'],
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
