
export default {
  props: {
    text: {
      type: String,
      required: true,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
